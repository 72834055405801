import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { StreamChat } from "stream-chat";
import { useParams, useHistory, useLocation } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
// import { format } from 'timeago.js';
import axios from "axios";
import {
  Channel,
  Chat,
  MessageInput,
  MessageList,
  Window,
} from "stream-chat-react";
import "stream-chat-react/dist/css/index.css";
import "./discussion.scss";
import { sendNotification } from "../../utils/notification";
import ChatHeader from "../../components/Chat/ChatHeader";
import MessageBody from "../../components/MessageBody/MessageBody";
import { bearerInstance, bearerInstanceWithToken } from "../../utils/API";
import {
  formatSourceText,
  currencyFormat,
  formatDenomination,
} from "../../utils/formatSourceText";
import {
  Alert,
  Button,
  Checkbox,
  Tooltip,
  Modal,
  Popover,
  Popconfirm,
  message,
} from "antd";
import {
  RightOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  QuestionOutlined,
  ExclamationCircleOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import {
  confirmModal,
  successMessage,
  successPaidMessage,
  errorMessage,
  uploadModal,
  chatIntroModal,
  UploadModal,
  InstructonsModal,
  InstructonsModalMerchant,
} from "../../utils/confirm";
import Countdown from "react-countdown";
import { VscDebugRestart } from "react-icons/vsc";
import { setReview } from "../../redux/data/data.actions";
import { removeSubstring } from "../../utils/removeStringFromText";
// import storage from "redux-persist/lib/storage";
// import Item from "antd/lib/list/Item";

const Discussion = () => {
  const user = useSelector((state) => state?.user?.userData);
  const coin = useSelector((state) => state?.coin?.defaultCoin);

  const param = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [tab, setTab] = useState("chats");

  const [client, setClient] = useState(null);
  const [channel, setChannel] = useState(null);

  const [chatting, setChatting] = useState(null);
  const [loading, setLoading] = useState(false);
  const [discussionData, setDiscussionData] = useState(null);
  const [merchant, setMerchantId] = useState(null);
  const [merchantDetails, setMerchantDetails] = useState(null);
  const [discussionDetails, setDiscussionDetails] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const [paid, setPaid] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [errorMsg, setErrorMsg] = useState(
    "An error occured, please try again"
  );
  const [raiseTimerIssue, setTimerIssue] = useState(false);
  const [checkPaidBtn, setCheckPaidBtn] = useState(false);
  const [checkSeenFund, setCheckSeenFund] = useState(true);
  const [checkRaiseIssue, setCheckRaiseIssue] = useState(false);
  const [isReceiptUploaded, setIsReceiptUploaded] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [discussionTimer, setDiscussionTimer] = useState(null);
  const [timeStand, setTimeStand] = useState(null);
  const [timerStatus, setTimerStatus] = useState("first");
  const [stageTwo, setStageTwo] = useState(true);
  const [isChatEnded, setIsChatEnded] = useState(false);
  const [receipt] = useState(true);
  const [readInstructions, setReadInstructions] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checkStageTwo, setCheckStageTwo] = useState(false);
  const [chatMembers, setChatMembers] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [issueHasBeenRaised, setIssueHasBeenRaised] = useState(false);
  const [isTimeCompleted, setIsTimeCompleted] = useState(false);
  const [updateApi, setUpdateApi] = useState(false);
  const [isTimeChange, setIsTimeChange] = useState(false);

  const checknextStageParam =
    discussionData?.discussion_data[0]?.stage === "1" &&
    discussionData?.discussion_data[0]?.merchant_seen === 1 &&
    discussionData?.discussion_data[0]?.dealer_paid === 1
      ? true
      : false;

  const [chatModal, setChatModal] = useState({
    continueToStageTwo: checknextStageParam,
    confirmPayment: false,
    seenPayment: false,
    discontinueChat: false,
    confirmEndChat: false,
    sentPayment: false,
    endChat: false,
    btnEndChat: false,
    updateMsg: false,
    updateMsgTwo: false,
    popOver: true,
    btnPaid: false,
    btnSeenPayment: false,
    acceptedChat: false,
    endMerchantChat: false,
    merchantPaid: false,
    raiseIssue: false,
    openErrorMsg: false,
    disputeModal: false,
    uploadReceipt: false,
    openInstructons: false,
    moderatorInChat: false,
    enterDispute: false,
    enterNextStage: false,
  });
  const [goToNextStage, setGoToNextStage] = useState(checknextStageParam);

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [isAdminEnteredChat, setIsAdminEnteredChat] = useState(false);
  const [isAdimInChatModal, setIsAdminInChatModal] = useState(false);
  const [awardDetails, setAwardDetails] = useState(null);
  // const [isReceiptUploaded, setIsReceiptUploaded] = useState(false)
  const [countDownTime, setCountDownTime] = useState(null);

  const successRef = useRef();
  const seenPaymentRef = useRef(null);
  const sentPaymentRef = useRef();
  const raiseIssueRef = useRef();
  const timerRef = useRef();
  const errorRef = useRef();
  const paidSuccessRef = useRef();
  const endChatRef = useRef();
  const msgRef = useRef();

  let checkMerchant = profileData?.dealer_user_name === user?.user_name;
  let renderDealerOnStages =
    discussionDetails?.stage === "1" ? !checkMerchant : checkMerchant;
  let renderMerchantOnStages =
    discussionDetails?.stage === "1" ? checkMerchant : !checkMerchant;

  let getReputationScore = checkMerchant
    ? discussionData?.dealer_reputation_data
    : discussionData?.merchant_reputation_data;

  const handleOpenModal = useCallback(
    (modalType, value) => {
      setChatModal((prev) => {
        return { ...prev, [modalType]: value };
      });
    },
    [chatModal]
  );

  // initialize ref
  useEffect(() => {
    let elem = seenPaymentRef.current;
    let elem3 = sentPaymentRef.current;
    let elem1 = endChatRef.current;
    let elem2 = raiseIssueRef.current;
    let elem4 = paidSuccessRef.current;
    let elem5 = errorRef.current;
    let elem6 = msgRef.current;
  }, []);

  const handleFetchDiscussionData = () => {
    if (param.id) {
      bearerInstance
        .get(`/fetch_discussion?discussion_id=${param.id}`)
        .then((res) => {
          setDiscussionData(res.data);
          setMerchantId(res.data?.merchant_data[0]?.id);
          setDiscussionDetails(res.data.discussion_data[0]);
          setProfileData(res.data?.deal_data[0]);
          setDiscussionTimer(res.data?.timer);
        })
        .catch((err) => {
          window.location.reload(true);
        });
    }
  };

  useEffect(() => {
    const checknextStageParam =
      discussionData?.discussion_data[0]?.stage === "1" &&
      discussionData?.discussion_data[0]?.merchant_seen === 1 &&
      discussionData?.discussion_data[0]?.dealer_paid === 1;
    handleOpenModal("continueToStageTwo", checknextStageParam);
    setGoToNextStage(checknextStageParam);
  }, [discussionData]);

  useEffect(() => {
    if (param.id) {
      setLoading(true);
      bearerInstance
        .get(`/fetch_discussion?discussion_id=${param.id}`)
        .then((res) => {
          setDiscussionData(res.data);
          setMerchantId(res.data?.merchant_data[0]?.id);
          setDiscussionDetails(res.data.discussion_data[0]);
          setProfileData(res.data?.deal_data[0]);
          setDiscussionTimer(res.data?.timer);

          if (res.data.deal_data[0].dealer_id === user.id)
            setChatting(res.data.merchant_data[0]);
          else setChatting(res.data.dealer_data[0]);

          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [param.id, user?.id, updateApi]);

  const handleGetDiscussionDetals = () => {
    bearerInstance
      .get(`/fetch_discussion?discussion_id=${param.id}`)
      .then((res) => {
        setMerchantId(res.data?.merchant_data[0]?.id);
        setDiscussionDetails(res.data.discussion_data[0]);
        setProfileData(res.data?.deal_data[0]);
        setDiscussionTimer(res.data?.timer);
      });
  };

  const init = useCallback(async () => {
    const res = await axios.get(
      `https://sustain-factory.live/chat/server.php?create-token=${user.id}`
    );
    const { token } = res.data;

    const userData = {
      id: user?.id,
      name: user?.user_name,
    };

    const chatClient = StreamChat.getInstance("2shvqv4hcrbh");
    await chatClient
      .connectUser(userData, token)
      .then((res) => {})
      .catch((err) => {});

    const chatChannel = chatClient.channel("messaging", param.id, {
      name: param.id,
      // members: [user?.id, `${discussionData?.merchant_data[0]?.user_name}`],
      // color: "green",
    });

    chatChannel
      .addMembers([user.id])
      .then((res) => {})
      .catch((err) => {});

    await chatChannel.watch({ presence: true });

    // custom event that fires when a particular event occurs

    chatChannel.on((event) => {
      if (event?.type === "paid_request") {
        setCheckPaidBtn(true);
        setTimerStatus("second");
        if (!isModalOpen) {
          // sentPaymentRef && sentPaymentRef.current.click();
          handleOpenModal("sentPayment", true);
          setIsModalOpen(true);
        }
      } else if (event?.type === "end-chat") {
        if (!isModalOpen) {
          setIsChatEnded(true);
          handleOpenModal("endChat", true);
          window.location.reload(true);
        }
      } else if (event?.type === "seen-payment") {
        if (!checkMerchant) {
          handleOpenModal("seenPayment", true);
          setIsTimeCompleted(false);
        }

        // call the discussions endpoint after seen payment
        handleFetchDiscussionData();
      } else if (event?.type === "finish_discussion") {
        setTimeout(() => {
          history.push("/reviews", {
            discussion_id: param.id,
            deal_id: discussionDetails?.deal_id,
          });
        }, 2000);
      } else if (event?.type === "next_stage") {
        window.location.reload(false);
      } else if (event?.type === "end_on_stageone") {
        handleOpenModal("discontinueChat", true);

        setTimeout(() => {
          history.push("/reviews", {
            discussion_id: param.id,
            deal_id: discussionDetails?.deal_id,
          });
        }, 3000);
      } else if (event?.type === "start_chat") {
        handleOpenModal("acceptedChat", true);
      } else if (event?.type === "end_merchant_chat") {
        handleOpenModal("endMerchantChat", true);
      } else if (event?.type === "fire_Issue") {
        setCheckRaiseIssue(true);
        // setTimerIssue(true);
        // raiseIssueRef && raiseIssueRef.current.click();
        if (!checkMerchant) {
          // !chatModal.raiseIssue && handleOpenModal("raiseIssue", true);
        }
      } else if (event?.type === "timer_message") {
        handleOpenModal("popOver", true);
      } else if (event?.type === "issue_raised") {
        setIssueHasBeenRaised(true);
        setTimerIssue(false);
      } else if (event?.type === "in_chat") {
        setIsAdminEnteredChat(true);
        setIsAdminInChatModal(true);
      } else if (event?.type === "award_value") {
        setAwardDetails(event.data);
        handleOpenModal("disputeModal", true);
      } else if (event?.type === "remove-mod") {
        handleOpenModal("moderatorInChat", true);
      } else if (event?.type === "continue-chat") {
        setIssueHasBeenRaised(false);
      } else if (event?.type === "get-timer") {
        // handleGetDiscussionDetals();
        // setIsTimeCompleted(false);
        setIsTimeChange(true);
      } else if (event?.type === "enter_dispute") {
        handleOpenModal("enterDispute", true);
      }
    });

    setChannel(chatChannel);
    setClient(chatClient);
  }, [profileData, user?.id]);

  function confirmReadInstructions() {
    if (discussionDetails?.custom_msg === 0) {
      // if (readInstructions === false) {
      let formData = new FormData();
      formData.append("discussion_id", param.id);
      formData.append("update_type", "custom_msg");
      bearerInstanceWithToken(user.token).post(`/update_discussion`, formData);
      // }
    }
  }

  const onChangeCheckbox = (e) => {
    setReadInstructions(e.target.checked);
  };

  useEffect(() => {
    dispatch(
      setReview({
        discussion_id: param.id,
        deal_id: discussionDetails?.deal_id,
        isDone: "yessssssssssssss",
      })
    );
  }, [discussionDetails]);


  useEffect(() => {
    const initializeChat = async () => {
      if (channel && checkMerchant) {
        try {
          await channel.sendEvent({
            type: "start_chat",
            text: "This initialises the chat",
          });
        } catch {
          // console.log("error occured");
        }
      }
    };

    initializeChat();
    setChatMembers(channel && Object.keys(channel?.state?.members).length);

    const handleCustomMessages = () => {
      const customMessage = {
        text: "this is the first stage of this trade, please check the instructions tab below for instructions",
        user: { user_id: user?.id, id: user?.id }, // Set the user property to the user's ID
        hidden: true,
      };

      const stageTwoMsg = {
        text: "this is the second stage of this trade, please check the instructions tab below for instructions",
        user: { user_id: user?.id, id: user?.id }, // Set the user property to the user's ID
        hidden: true,
      };

      const instructions = {
        text: `${
          discussionData?.deal_data[0]?.no_third_party
            ? "Third party payments are NOT allowed for this trade. Please endeavor to follow this rule and other rules set aside by the vendor below:"
            : ""
        }
${
  discussionData?.deal_data && discussionData?.deal_data[0]?.deal_summary
    ? `\n${discussionData?.deal_data[0]?.deal_summary}`
    : ""
}`,
        user: { user_id: user?.id, id: user?.id }, // Set the user property to the user's ID
        hidden: true,
      };

      // send custom message when component mounts
      // if (discussionDetails?.accepted === 1 || chatModal.acceptedChat) {

      if (
        discussionDetails?.custom_msg === 0 &&
        chatModal.updateMsg === false
      ) {
        if (!checkMerchant) {
          channel &&
            channel
              .sendMessage(customMessage)
              .then((res) => {
                // fire merchants instructions message
                channel && channel.sendMessage(instructions);
                let formData = new FormData();
                formData.append("discussion_id", param.id);
                formData.append("update_type", "custom_msg");
                bearerInstanceWithToken(user.token)
                  .post(`/update_discussion`, formData)
                  .then((res) => {
                    handleOpenModal("updateMsg", true);
                  })
                  .catch((err) => {});
              })
              .catch((err) => {});
        }
        // }
      }

      // custom message fires for stage two
      if (
        discussionDetails?.stage === "2" &&
        discussionDetails?.custom_msg_2 === 0 &&
        !chatModal.updateMsgTwo
      ) {
        if (!checkMerchant) {
          channel &&
            channel
              .sendMessage(stageTwoMsg)
              .then((res) => {
                let formData = new FormData();
                formData.append("discussion_id", param.id);
                formData.append("update_type", "custom_msg_2");
                bearerInstanceWithToken(user.token)
                  .post(`/update_discussion`, formData)
                  .then((res) => {
                    handleOpenModal("updateMsgTwo", true);
                  })
                  .catch((err) => {});
              })
              .catch((err) => {});
        }
      }
    };

    handleCustomMessages();
  }, [channel, profileData]);

  let stage = discussionDetails?.stage === "1";

  let checkStage =
    discussionDetails?.stage === "1" ? !checkMerchant : checkMerchant;

  useEffect(() => {
    // Render custom default message for stage one of the transacton

    // if (discussionDetails?.custom_msg === 0) {
    if (
      discussionDetails?.status === "canceled" ||
      discussionDetails?.status === "completed" ||
      discussionDetails?.status === "dispute"
    ) {
      return false;
    } else {
      if (channel) {
        handleOpenModal("openInstructons", true);
      }
    }

    // }
  }, [channel]);

  useEffect(() => {
    if (timerStatus === "second") {
      handleGetDiscussionDetals();
      setTimerStatus("first");
    }

    const setTimer = async () => {
      if (!discussionTimer) {
        return;
      } else {
        await setTimeStand(discussionTimer?.diff * 1000);
        await setCountDownTime(Date.now() + 700000);
      }
    };

    setTimer();
  }, [discussionTimer, timerStatus, discussionDetails, discussionData]);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (channel) {
      channel.on((event) => {
        if (event.type === "paid") setPaid(true);
      });
    }
  }, [channel]);

  useEffect(() => {
    if (channel) {
      channel.on("message.new", (event) => {
        if (event.user.id !== user.id)
          sendNotification("user", event.message.text, `/chat/${param.id}`);
      });
    }
  }, [channel, user.id, param.id]);

  // useEffect(() => {
  //   const userLoggin = async () => {
  //     await channel?.sendEvent({
  //       type: "raiseIssues",
  //       text: "Hey there, long time no see!",
  //     });
  //   };

  //   userLoggin();
  // }, [channel]);

  useEffect(() => {
    if (merchant) {
      bearerInstance
        .get(`/profile?id=${merchant}`)
        .then((res) => {
          setMerchantDetails(res.data);
        })
        .catch((err) => {});
    }
  }, [merchant]);

  const handleCheck = () => {
    setChecked(!checked);
  };

  const handleCheckForStageTwo = () => {
    setCheckStageTwo(!checkStageTwo);
  };

  const handleFileUpload = (event) => {
    if (event) {
      // console.log("event just fired");
      setIsReceiptUploaded(true);
    } else {
      // console.log("event unsuccessfull");
    }
  };

  useEffect(() => {
    window.addEventListener("change", handleFileUpload);

    return () => window.removeEventListener("change", () => {});
  }, []);

  // call  event on stage 2 when user continues in a chat
  const resetUserTimer = async () => {
    if (channel) {
      await channel.sendEvent({
        type: "get-timer",
      });
    }
  };

  const handleResumeTrade = async () => {
    await channel.sendEvent({
      type: "resume_trade",
      text: "Let users continue in trade",
    });
  };

  const handlePaid = () => {
    confirmModal(
      <h3 style={{ display: "inline", whiteSpace: "normal" }}>
        have you sent the money to
        <span className="username-green-n dealer-username">
          {discussionDetails?.stage === "1"
            ? ` @${discussionData?.merchant_data[0]?.user_name}`
            : ` @${discussionData?.dealer_data[0]?.user_name}`}
        </span>
        ?
      </h3>,
      // render different modal message on stage 1 and stage 2
      <>
        {discussionDetails?.stage === "1" ? (
          <p>
            make sure you have sent exactly{" "}
            {formatDenomination(discussionDetails?.source_currency)}
            {currencyFormat(discussionDetails?.source_value)} to{" "}
            {discussionData?.merchant_data[0]?.user_name}
            ’s provided {formatSourceText(discussionDetails?.source)}.
          </p>
        ) : (
          <p>
            make sure you have sent exactly{" "}
            {formatDenomination(discussionDetails?.destination_currency)}
            {currencyFormat(discussionDetails?.destination_value)} to{" "}
            {discussionData?.dealer_data[0]?.user_name}
            ’s {formatSourceText(discussionDetails?.destination)}.
          </p>
        )}
        <p>If you are sure, click “ok” below.</p>
      </>,
      async () => {
        setUpdateApi(!updateApi);
        let formData = new FormData();
        formData.append("discussion_id", param.id);
        try {
          // await channel.sendEvent({
          //   type: "paid",
          // });
          await bearerInstanceWithToken(user.token).post("/paid", formData);
          await handleCheckPaid();
          setTimerStatus("second");
          handleOpenModal("btnPaid", true);
          handleOpenModal("uploadReceipt", true);
          handleOpenModal("merchantPaid", true);
          handleChangeTimerMsg();
          continueChatInprogress();
          kickModOut();
          handleResumeTrade();
          setIssueHasBeenRaised(false);
          // if (discussionData?.stage === "2") {
          // await handleGetDiscussionDetals();
          resetUserTimer();
          // setIsTimeCompleted(false);
          setIsTimeChange(true);

          // }
        } catch (err) {
          setErrorMsg(err.response.data.message);
          errorRef.current.click();
          // handleOpenModal("openErrorMsg", true);
        }
      }
    );
  };

  const onChange = (e) => {
    e && setDisableBtn(e.target.checked);
  };

  const handleEndChat = async (action, redirect, data) => {
    let formData = new FormData();
    formData.append("discussion_id", param.id);
    // const payload = { discussion_id: param.id };
    try {
      // await channel.sendEvent({
      //   type: "end-chat",
      // });
      await bearerInstanceWithToken(user.token).post(
        "/end_discussion",
        formData
      );

      // Function fires to let user know the chat has ended. passed as an arguement
      action && action();

      setTimeout(() => {
        history.push(
          redirect || `/reviews`,
          data || {
            discussion_id: param.id,
            deal_id: discussionDetails?.deal_id,
          }
        );
        window.location.reload(false);
      }, 5000);
    } catch (err) {
      if (err?.response?.status === 400) {
        // Function fires to let user know the chat has ended an arguement
        action && action();
        setErrorMsg(err?.response?.data?.message);
      } else {
        setErrorMsg(err?.response?.data?.message);
        errorRef.current.click();
        // handleOpenModal("openErrorMsg", true);
      }
    }
  };

  const endChat = () => {
    confirmModal(
      <h3 style={{ fontSize: "16px" }}>are you sure?</h3>,
      <>
        <Alert
          type="error"
          showIcon
          description="do not end trade if you've already made
          this payment. for any other issues,
          click on back and start a dispute."
        />
        <Checkbox
          className="message-check"
          onChange={onChange}
          style={{ marginTop: "10px" }}
        >
          i confirm that I have not paid and I wish to end trade now
        </Checkbox>
      </>,
      async () => {
        await handleEndChat(handleOpenModal("btnEndChat", true));

        //notifends chat on the merchant side
        await channel.sendEvent({
          type: "end_merchant_chat",
          text: "Event ends merchant chat",
        });
      },
      disableBtn
    );
  };

  const raiseIssues = () => {
    confirmModal(
      <h3 style={{ fontSize: "16px" }}>
        you are about to enter into a dispute.
      </h3>,
      <>
        <p>
          since you have indicated an issue with this trade, we will now invite
          a lily moderator to help resolve the issue.
        </p>

        <p>
          ensure you have all proofs of payment and ensure you have adhered
          strictly to our terms.
        </p>
      </>,
      handleNewIssue,
      disableBtn
    );
  };

  const handleCloseDispute = async () => {
    let formData = new FormData();
    formData.append("dispute_id", discussionData?.dispute_data[0]?.id);
    try {
      await bearerInstanceWithToken(user?.token).post(
        "/close_dispute",
        formData
      );
    } catch (err) {
      message.error(err.response?.data?.message);
    }
  };

  // Seen Payment modal
  const seenPayment = () => {
    confirmModal(
      <h3 style={{ fontSize: "16px" }}>have you seen payment?</h3>,
      <>
        <p>
          ensure you have seen payment of{" "}
          {stage
            ? `${formatDenomination(
                discussionDetails?.source_currency
              )}${currencyFormat(discussionDetails?.source_value)}
                    `
            : `${formatDenomination(
                discussionDetails?.destination_currency
              )}${currencyFormat(discussionDetails?.destination_value)}`}{" "}
          before proceeding
        </p>
        <p>
          if you haven't seen the full payment or if there's any issue, please
          go back and wait for 2 hours to start a dispute.
        </p>
      </>,
      async () => {
        setUpdateApi(!updateApi);
        discussionDetails?.status === "dispute" && (await handleCloseDispute());

        let formData = new FormData();
        formData.append("discussion_id", param.id);
        // const payload = { discussion_id: param.id };
        try {
          // await channel.sendEvent({
          //   type: "end-chat",
          // });
          await bearerInstanceWithToken(user.token).post(
            discussionDetails?.stage === "1" ? "/seen" : "/finish_discussion",
            formData
          );

          // successRef.current.click();
          fireSeenPayment();
          handleChangeTimerMsg();
          handleOpenModal("btnSeenPayment", true);
          continueChatInprogress();
          kickModOut();
          handleResumeTrade();
          setIssueHasBeenRaised(false);
          // await handleGetDiscussionDetals();
          // setIsTimeCompleted(false);

          if (discussionDetails?.stage === "2") {
            handleFinishDiscussion();

            // setTimeout(() => {
            //   history.push(`/chat`);
            //   window.location.reload(true)
            // }, 5000);
          }
        } catch (err) {
          setErrorMsg(err.response.data.message);
          // errorRef.current.click();
          message.error(err.response?.data?.message);
        }
      },
      disableBtn
    );
  };

  const continueChatInprogress = async () => {
    if (channel) {
      await channel.sendEvent({
        type: "continue-chat",
      });
    }
  };

  const Completionist = () => <span>00.00.00</span>;

  const raiseIssue = async () => {
    (await channel) &&
      channel.sendEvent({
        type: "fire_Issue",
        text: "Raise an issue event!",
      });
  };

  const fireEndChat = async () => {
    if (channel) {
      await channel.sendEvent({
        type: "end-chat",
      });
    }
  };

  // Renderer callback with condition
  const firstRenderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state

      if (
        discussionDetails?.stage === "1" &&
        discussionTimer?.interval === 1800
      ) {
        if (!isChatEnded) {
          handleEndChat(handleOpenModal("endChat", true));
          setIsTimeCompleted(true);
        } else {
          fireEndChat();
        }
      } else {
        raiseIssue();
        setTimerIssue(true);
        setIsTimeCompleted(true);
      }

      // handleEndTimer();
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <span>
          0{hours}:{minutes}:{seconds}
        </span>
      );
    }
  };

  const secondRenderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      if (discussionDetails?.stage === "1") {
        raiseIssue();
        setTimerIssue(true);
        setIsTimeCompleted(true);
      } else {
        raiseIssue();
        setTimerIssue(true);
        setIsTimeCompleted(true);
      }

      // localStorage.setItem("checkIssue", true);
      // Render a complete state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <span>
          0{hours}:{minutes}:{seconds}
        </span>
      );
    }
  };

  const handleRedirect = () => {
    setIsModalOpen(false);

    history.push(`/reviews`);
    window.location.reload(false);
  };

  let memsIdArr = channel && Object.keys(channel.state.members);
  let getMem =
    channel && memsIdArr.filter((item) => item != `${profileData?.dealer_id}`);

  let targetUserID = channel && `${getMem[0]}`;

  // useEffect(() => {
  //  ;
  // }, []);

  const handleCheckPaid = async () => {
    await channel.sendEvent({
      type: "paid_request",
      text: "user paid",
    });
  };

  const handleFinishDiscussion = async () => {
    await channel.sendEvent({
      type: "finish_discussion",
    });

    localStorage.removeItem(`isMessageFired${discussionDetails?.deal_id}`);
  };

  // function fires event if merchant clicks on seen payment
  const fireSeenPayment = async () => {
    if (channel) {
      await channel.sendEvent({
        type: "seen-payment",
      });
    }

    setStageTwo(true);
  };

  const kickModOut = async () => {
    if (channel) {
      await channel.sendEvent({
        type: "mod-out",
      });
    }
  };

  const openFS = () => {
    // setIsReceiptUploaded(true);
    document.getElementsByClassName("rfu-file-input")[0].click();
  };

  // function helps upload selected image to the chat
  const uploadImg = () => {
    document.getElementsByClassName("str-chat__send-button")[0].click();
    handleOpenModal("uploadReceipt", false);
  };

  // function fire modal, to encourage user to provide a payment slip
  const handleInitiateFileUpload = () => {
    uploadModal(openFS, uploadImg, isReceiptUploaded);
  };

  // function fires modal encouraging the merchant to confirm receipt before releasing funds
  const handleConfirmReceiptPayment = () => {
    successMessage(
      `ensure  ${
        discussionDetails?.stage === "1"
          ? discussionData?.dealer_data[0]?.user_name
          : discussionData?.merchant_data[0]?.user_name
      } provides a proof of payment (receipt) before proceeding to funds release.`,
      null,
      `confirm receipt from ${
        discussionDetails?.stage === "1"
          ? discussionData?.dealer_data[0]?.user_name
          : discussionData?.merchant_data[0]?.user_name
      }`
    );
  };

  const handleConfirmPayment = () => {
    handleOpenModal("seenPayment", false);
    handleOpenModal("continueToStageTwo", true);
  };

  const handleNextStage = async () => {
    await channel.sendEvent({
      type: "next_stage",
      text: "move to the next stage",
    });

    handleOpenModal("continueToStageTwo", false);
  };

  const handleEndChatOnStageOne = async () => {
    await channel.sendEvent({
      type: "end_on_stageone",
      text: "move to the next stage",
    });

    handleEndChat(handleOpenModal("btnEndChat", true), "/reviews", {
      discussion_id: param.id,
      deal_id: discussionDetails?.deal_id,
    });
  };

  const handleChangeTimerMsg = async () => {
    await channel.sendEvent({
      type: "timer_message",
      text: "change the timer message",
    });
  };

  async function handleNewIssue() {
    setLoadingBtn(true);

    let formData = new FormData();
    formData.append("discussion_id", param.id);
    try {
      await bearerInstance.post("/new_dispute", formData);
      setLoadingBtn(false);
      setIssueHasBeenRaised(true);
      handleOpenModal("enterDispute", true);
      setCheckRaiseIssue(false);
      setUpdateApi(!updateApi);
      message.success("this trade has entered into a dispute");

      // use custom event to change the issue raised state
      await channel.sendEvent({
        type: "issue_raised",
        text: "issue has been raised",
      });

      await channel.sendEvent({
        type: "enter_dispute",
        text: "user enters a dispte",
      });
      await handleOpenModal("raiseIssue", false);
    } catch (err) {
      message.error(err.response?.data?.message);
    }
  }

  // function checks if user accepts to read instructions
  const acceptInstructions = () => {
    if (!checked) {
      message.error("you must accept the terms by checking the box");
    } else {
      handleOpenModal("openInstructons", false);
      setIsReceiptUploaded(false);
    }
  };

  // initialize the countdown time

  let countdown = Date.now() + timeStand;
  let changeTimer = Date.now() + 7200000;

  // let countdown = Date.now() + 30000;

  const memoizedCountdown = useMemo(() => {
    return (
      <div
        onClick={() => handleOpenModal("popOver", true)}
        style={{ cursor: "pointer" }}
      >
        <Countdown
          date={isTimeChange ? changeTimer : countdown}
          // date={changeTimer}
          renderer={
            discussionTimer?.interval === 1800 ? firstRenderer : secondRenderer
          }

          // renderer={firstRenderer}
        />
      </div>
    );
  }, [discussionTimer, timeStand, discussionData, isTimeChange]);

  // call endpoint for next stage
  const handleCallNextStage = () => {
    handleOpenModal("enterNextStage", true);

    let formData = new FormData();
    formData.append("discussion_id", discussionDetails?.id);
    formData.append("update_type", "stage");
    formData.append("value", "2");

    bearerInstanceWithToken(user?.token)
      .post(`/update_discussion`, formData)
      .then((res) => {
        handleOpenModal("enterNextStage", false);
        handleNextStage();
      })
      .catch(() => {
        handleOpenModal("enterNextStage", false);
      });
  };

  return (
    <>
      <div className="message">
        <UploadModal
          func={openFS}
          uploadImg={uploadImg}
          isUploaded={isReceiptUploaded}
          show={chatModal.uploadReceipt}
        />

        {/* instructions modal */}
        {chatModal.openInstructons && checkStage ? (
          <>
            <InstructonsModal
              stage={stage}
              discussionDetails={discussionDetails}
              show={chatModal.openInstructons}
              onOk={acceptInstructions}
              checked={checked}
              handleCheck={handleCheck}
              discussionData={discussionData}
            />
          </>
        ) : (
          <InstructonsModalMerchant
            stage={stage}
            discussionDetails={discussionDetails}
            show={chatModal.openInstructons}
            onOk={acceptInstructions}
            checked={checked}
            handleCheck={handleCheck}
            discussionData={discussionData}
          />
        )}

        {/* modal displays when moderator leaves the chat */}
        <Modal
          visible={chatModal.moderatorInChat}
          onOk={() => handleOpenModal("moderatorInChat", false)}
          cancelText={<p className="cancel-text">.</p>}
          title={`moderator left the chat`}
        >
          <p>moderator left the chat and the trade is back in progress.</p>
        </Modal>

        {/* modal displays when moderator has awarded the escrow value */}
        <Modal
          visible={chatModal.disputeModal}
          onOk={handleRedirect}
          cancelText={<p className="cancel-text">.</p>}
          title={`${awardDetails?.amount} ${removeSubstring(
            discussionDetails?.in_escrow_coin
          )} awarded to ${awardDetails?.name}`}
        >
          {/* {`after reviewing all evidences and the terms for this discussion, ${awardDetails?.amount} in the escrow has been awarded to @${awardDetails?.name}, which completes this discussion.`} */}
          {awardDetails?.message}
        </Modal>

        {/* Modal display to tell the dealer that they have successfully ended their chat */}
        <Modal
          visible={chatModal.btnEndChat}
          onOk={() => handleOpenModal("btnEndChat", false)}
          cancelText={<p className="cancel-text">.</p>}
          title="end trade"
        >
          {`you successfully ended your trade with ${discussionData?.merchant_data[0]?.user_name}. you can always restart a discussion on this deal at a later time.`}
        </Modal>

        {/* confirm payment modals for merchant and dealer */}
        {discussionDetails?.stage === "1" && !checkMerchant && (
          <Modal
            title="payment confirmation"
            visible={chatModal.seenPayment}
            onOk={handleConfirmPayment}
            cancelText={<p className="cancel-text">.</p>}
          >
            <p>{`${
              discussionData?.merchant_data[0]?.user_name
            } has seen your payment of ${formatDenomination(
              discussionDetails?.source_currency
            )}${discussionDetails?.source_value}`}</p>
          </Modal>
        )}

        {discussionDetails?.stage !== "1" && checkMerchant && (
          <Modal
            title="payment confirmation"
            visible={chatModal.seenPayment}
            onOk={() => handleOpenModal("seenPayment", false)}
            cancelText={<p className="cancel-text">.</p>}
          >
            <p>{`${
              discussionData?.dealer_data[0]?.user_name
            } has seen your payment of ${formatDenomination(
              discussionDetails?.destination_currency
            )}${currencyFormat(discussionDetails?.destination_value)}`}</p>
          </Modal>
        )}

        {/* confirm seen payment modals for merchant and dealer */}
        {discussionDetails?.stage === "1" && checkMerchant && (
          <Modal
            title="confirming payment seen"
            visible={chatModal.seenPayment}
            onOk={handleConfirmPayment}
            cancelText={<p className="cancel-text">.</p>}
          >
            <p>{` you’ve seen the payment of ${formatDenomination(
              discussionDetails?.source_currency
            )}${currencyFormat(
              discussionDetails?.source_value
            )}. Please wait for ${
              discussionData?.dealer_data[0]?.user_name
            } to proceed with this trade.`}</p>
          </Modal>
        )}

        {discussionDetails?.stage !== "1" && !checkMerchant && (
          <Modal
            title="confirming payment seen"
            visible={chatModal.seenPayment}
            onOk={() => handleOpenModal("seenPayment", false)}
            cancelText={<p className="cancel-text">.</p>}
          >
            <p>{` you’ve seen the payment of ${formatDenomination(
              discussionDetails?.destination_currency
            )}${currencyFormat(discussionDetails?.destination_value)}.`}</p>
          </Modal>
        )}

        {/* continue chat modal for the dealer */}
        {discussionDetails?.stage === "1" && !checkMerchant && (
          <Modal
            title="continue to stage two"
            visible={chatModal.continueToStageTwo}
            onOk={handleCallNextStage}
            cancelText={<p>end trade</p>}
            okText={<p>continue to stage 2</p>}
            onCancel={handleEndChatOnStageOne}
            okButtonProps={{
              disabled: !checkStageTwo,
              loading: chatModal.enterNextStage,
            }}
            maskClosable={false}
            // confirmLoading={true}
          >
            {/* <p>the first stage of this trade has ended</p> */}
            <p>
              {/* {` you are now about to proceed to the second stage where you will be
              selling ${discussionDetails?.in_escrow} BTC for
              ${formatSourceText(discussionDetails?.destination)} funds.`} */}
              the first stage of this trade has ended. you are now about to
              proceed to the second stage.
            </p>
            {/* <p>
              {` if you DO NOT want to proceed, you can end the trade now and
              ₦${currencyFormat(
                discussionDetails?.destination_value
              )} worth of BTC will be returned to your bitcoin
              wallet.`}
            </p> */}
            <p>
              {` if you DO NOT want to proceed, you can end the trade now and
              ${discussionDetails?.in_escrow} ${removeSubstring(
                discussionDetails?.in_escrow_coin
              )} will be returned to your ${removeSubstring(
                discussionDetails?.in_escrow_coin
              )}
              wallet.`}
            </p>
            remember that your funds are securely held in the escrow and you can
            raise a dispute at anytime.
            <Checkbox
              className="message-check"
              style={{ marginTop: "20px" }}
              onChange={handleCheckForStageTwo}
              checked={checkStageTwo}
            >
              i confirm I understand this and take full responsibility for my
              decisions.
            </Checkbox>
          </Modal>
        )}

        {discussionDetails?.stage === "1" && checkMerchant && (
          <Modal
            title="trade discontinued"
            cancelText={<p className="cancel-text">.</p>}
            visible={chatModal.discontinueChat}
            onOk={() => {
              history.push(`/chat`);
              window.location.reload(true);
            }}
          >
            <p>{`the dealer has discontinued the trade`}</p>
          </Modal>
        )}

        {/* Modal fires when a payment has been made and confirmed */}

        {discussionDetails?.stage === "1" && checkMerchant && (
          <Modal
            visible={chatModal.sentPayment}
            title={`check payment from @${
              discussionDetails?.stage === "1"
                ? discussionData?.dealer_data[0]?.user_name
                : discussionData?.merchant_data[0]?.user_name
            }`}
            onOk={() => {
              handleConfirmReceiptPayment();
              handleOpenModal("sentPayment", false);
            }}
            cancelText={<p className="cancel-text">.</p>}
          >
            <p>
              {`${
                discussionData?.dealer_data[0]?.user_name
              } just made a payment of ${formatDenomination(
                discussionDetails?.source_currency
              )}${currencyFormat(
                discussionDetails?.source_value
              )} into your ${formatSourceText(discussionDetails?.source)}.`}
            </p>
            <p>
              {" "}
              click on “seen payment” button below to confirm that you have seen
              the payment. do not click on the button if you have not seen the
              payment, to avoid fund loss.
            </p>
          </Modal>
        )}

        {discussionDetails?.stage !== "1" && !checkMerchant && (
          <Modal
            visible={chatModal.sentPayment}
            title={`check payment from @${
              discussionDetails?.stage === "1"
                ? discussionData?.dealer_data[0]?.user_name
                : discussionData?.merchant_data[0]?.user_name
            }`}
            onOk={() => {
              handleConfirmReceiptPayment();
              handleOpenModal("sentPayment", false);
              setIsModalOpen(false);
            }}
            cancelText={<p className="cancel-text">.</p>}
          >
            <p>
              {`${
                discussionData?.merchant_data[0]?.user_name
              } just made a payment of 
              ${formatDenomination(discussionDetails?.destination_currency)}
              ${currencyFormat(discussionDetails?.destination_value)}
               into your ${formatSourceText(discussionDetails?.destination)}`}
            </p>
            <p>
              click on “seen payment” button below to confirm that you have seen
              the payment. do not click on the button if you have not seen the
              payment, to avoid fund loss.
            </p>
          </Modal>
        )}

        {/* modal notifies merchant when the dealer ends a chat */}
        {checkMerchant && (
          <Modal
            title="trade ended"
            visible={chatModal.endMerchantChat}
            cancelText={<p className="cancel-text">.</p>}
            onOk={handleRedirect}
          >
            <p>
              {discussionData?.dealer_data[0]?.user_name} has ended the trade
            </p>
          </Modal>
        )}

        {/* Modal fires when user didn't make a payment */}
        {/* <button
          onClick={() =>
            successMessage(
              `${
                discussionData?.dealer_data[0]?.user_name
              } failed to make a payment of $${currencyFormat(
                discussionDetails?.source_value
              )} into your ${formatSourceText(discussionDetails?.source)}. ${
                discussionData?.dealer_data[0]?.user_name
              } failed to make a payment of $500 into your PayPal. Click on “raise an issue” button to start a dispute. A moderator will be available to resolve this incident.`,
              null,
              "raise issue"
            )
          }
          ref={raiseIssueRef}
          style={{ display: "none" }}
        /> */}

        <Modal
          visible={chatModal.raiseIssue}
          // visible={true}
          title="raise issue"
          cancelText={<p>cancel</p>}
          onCancel={() => handleOpenModal("raiseIssue", false)}
          onOk={() => handleOpenModal("raiseIssue", false)}
          confirmLoading={loadingBtn}
        >
          <p>{` ${
            discussionData?.dealer_data &&
            discussionData?.dealer_data[0]?.user_name
          } failed to make a payment of ${formatDenomination(
            discussionDetails?.source_currency
          )}${currencyFormat(
            discussionDetails?.source_value
          )} into your ${formatSourceText(discussionDetails?.source)}.`}</p>
          <p>
            click on “raise an issue” button to start a dispute. a moderator
            will be available to resolve this incident.
          </p>
        </Modal>

        {/* modal fires when stage one count down ends */}
        <Modal
          visible={chatModal.endChat}
          onOk={handleRedirect}
          title="trade ended"
          cancelText={<p className="cancel-text">.</p>}
        >
          <p>your trade ended due to inactivity, you can restart the trade.</p>
        </Modal>

        {/* fixing the error modal, resulted to error,  */}
        {/* <Modal
          visible={chatModal.openErrorMsg}
          onOk={handleOpenModal("openErrorMsg", false)}
          title="error message"
          cancelText={<p>.</p>}
        >
          <p>{errorMsg}</p>
        </Modal> */}

        <button
          onClick={() => errorMessage(errorMsg)}
          ref={errorRef}
          style={{ display: "none" }}
        />

        <Modal
          title="trade in dispute"
          cancelText={<p className="cancel-text">.</p>}
          visible={chatModal.enterDispute}
          onOk={() => {
            handleOpenModal("enterDispute", false);
          }}
        >
          <p>{`this trade is now in dispute. a moderator will join you shortly.`}</p>
        </Modal>

        <Modal
          title="moderator online"
          cancelText={<p className="cancel-text">.</p>}
          visible={isAdimInChatModal}
          onOk={() => {
            setIsAdminInChatModal(false);
          }}
        >
          <p>{`moderator is online now. you can both air your grievances`}</p>
        </Modal>

        {/* <button
          onClick={() =>
            successPaidMessage(
              discussionData?.merchant_data[0]?.user_name,
              () => handleInitiateFileUpload()
            )
          }
          ref={paidSuccessRef}
          style={{ display: "none" }}
        /> */}

        <div className="message-wrapper">
          {!channel || !client || loading ? (
            <Loader />
          ) : (
            <Chat client={client} theme="messaging light">
              <Channel channel={channel}>
                <Window>
                  <>
                    <ChatHeader
                      username={discussionData?.merchant_data[0]?.user_name}
                      dislikes={
                        merchantDetails?.profile_data[0]?.total_negative_reviews
                      }
                      likes={
                        merchantDetails?.profile_data[0]?.total_positive_reviews
                      }
                      status={merchantDetails?.status}
                      discussionData={discussionDetails}
                      profileData={profileData}
                      channel={channel}
                      location={discussionData}
                      acceptedChat={chatModal.acceptedChat}
                      checkMerchant={checkMerchant}
                      issueHasBeenRaised={issueHasBeenRaised}
                      score={getReputationScore}
                    />
                    <MessageList
                      hideDeletedMessages={true}
                      messageActions={["reply", "quote"]}
                      additionalMessageInputProps={{}}
                      Message={(messageProps) => (
                        <MessageBody {...messageProps} />
                      )}
                    />
                  </>

                  <div className="message-wrapper-box">
                    <div className="message-box">
                      <MessageInput
                        grow={true}
                        additionalTextareaProps={{
                          placeholder: "type a message...",
                        }}
                        disabled={
                          discussionDetails?.status === "canceled" ||
                          discussionDetails?.status === "completed"
                            ? true
                            : false
                        }
                      />
                    </div>

                    {discussionData?.dispute_data[0]?.status !== "resolved" && (
                      <div className="message-wrapper-action">
                        {discussionDetails?.status === "canceled" ? (
                          ""
                        ) : (
                          <div
                            style={{
                              display:
                                discussionDetails?.merchant_seen === 0
                                  ? "flex"
                                  : "none",
                            }}
                          >
                            {/* initial button render, on stage one */}
                            {discussionDetails?.merchant_seen === 0 && (
                              <div>
                                {!checkMerchant ? (
                                  <>
                                    <Button
                                      disabled={
                                        !checkPaidBtn &&
                                        discussionDetails?.dealer_paid === 0
                                          ? false
                                          : checkPaidBtn ||
                                            discussionDetails?.dealer_paid === 1
                                          ? true
                                          : null
                                      }
                                      onClick={endChat}
                                      type="text"
                                    >
                                      end trade
                                      <RightOutlined />
                                    </Button>

                                    {!checkPaidBtn &&
                                    discussionDetails?.dealer_paid === 0 ? (
                                      <Button
                                        type="text"
                                        onClick={handlePaid}
                                        // disabled={
                                        //   discussionDetails?.accepted === 0 ||
                                        //   !chatModal.acceptedChat
                                        //     ? true
                                        //     : false
                                        // }
                                      >
                                        <CheckOutlined />i have paid
                                      </Button>
                                    ) : !checkPaidBtn ||
                                      discussionDetails?.dealer_paid === 1 ? (
                                      <Button
                                        type="text"
                                        onClick={raiseIssues}
                                        disabled={
                                          !raiseTimerIssue &&
                                          discussionDetails?.status !==
                                            "dispute"
                                            ? true
                                            : discussionDetails?.status ===
                                              "dispute"
                                            ? true
                                            : false
                                        }
                                      >
                                        <QuestionOutlined />
                                        raise issue
                                      </Button>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {/* disable the end chat button for the merchant  */}
                                    {/* <Button
                                    disabled={
                                      !checkPaidBtn &&
                                      discussionDetails?.dealer_paid === 0
                                        ? false
                                        : checkPaidBtn ||
                                          discussionDetails?.dealer_paid === 1
                                        ? true
                                        : null
                                    }
                                    onClick={endChat}
                                    type="text"
                                  >
                                    end chat
                                    <RightOutlined />
                                  </Button> */}

                                    <Button
                                      type="text"
                                      disabled={
                                        discussionDetails?.dealer_paid === 0 ||
                                        chatModal.btnSeenPayment
                                          ? true
                                          : false
                                      }
                                      onClick={seenPayment}
                                    >
                                      <CheckOutlined />
                                      seen payment
                                    </Button>

                                    <Button
                                      type="text"
                                      onClick={raiseIssues}
                                      disabled={
                                        !raiseTimerIssue &&
                                        discussionDetails?.status !== "dispute"
                                          ? true
                                          : discussionDetails?.status ===
                                            "dispute"
                                          ? true
                                          : false
                                      }
                                    >
                                      <QuestionOutlined />
                                      raise issue
                                    </Button>
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                        )}

                        {discussionDetails?.status === "canceled" ||
                        discussionDetails?.status === "completed" ? (
                          ""
                        ) : (
                          <div
                            style={{
                              display:
                                discussionDetails?.merchant_seen === 1
                                  ? "flex"
                                  : "none",
                            }}
                          >
                            {discussionDetails?.merchant_seen === 1 && (
                              <div>
                                {checkMerchant ? (
                                  <>
                                    {/* <Button
                                    disabled={
                                      !checkPaidBtn &&
                                      discussionDetails?.dealer_paid === 0
                                        ? false
                                        : checkPaidBtn ||
                                          discussionDetails?.dealer_paid === 1
                                        ? true
                                        : null
                                    }
                                    onClick={endChat}
                                    type="text"
                                  >
                                    end chat
                                    <RightOutlined />
                                  </Button> */}

                                    <Button
                                      type="text"
                                      onClick={handlePaid}
                                      disabled={
                                        // !checkPaidBtn ||
                                        // discussionDetails?.merchant_paid === 0
                                        //   ? false
                                        //   : discussionDetails?.merchant_paid ===
                                        //     1
                                        //   ? true
                                        //   : true

                                        discussionDetails?.merchant_paid === 1
                                          ? true
                                          : !checkPaidBtn
                                          ? false
                                          : checkPaidBtn
                                          ? true
                                          : true
                                      }
                                    >
                                      <CheckOutlined />i have paid
                                    </Button>

                                    <Button
                                      type="text"
                                      onClick={raiseIssues}
                                      // disabled={
                                      //   !raiseTimerIssue &&
                                      //   discussionDetails?.status !== "dispute"
                                      //     ? true
                                      //     : raiseTimerIssue
                                      //     ? // ||
                                      //       //   discussionDetails?.status ===
                                      //       //     "dispute"
                                      //       false
                                      //     : true
                                      // }
                                      disabled={
                                        !raiseTimerIssue &&
                                        discussionDetails?.status !== "dispute"
                                          ? true
                                          : discussionDetails?.status ===
                                            "dispute"
                                          ? true
                                          : false
                                      }
                                    >
                                      <QuestionOutlined />
                                      raise issue
                                    </Button>
                                  </>
                                ) : (
                                  <>
                                    <Button
                                      disabled={true}
                                      onClick={endChat}
                                      type="text"
                                    >
                                      end trade
                                      <RightOutlined />
                                    </Button>

                                    <Button
                                      type="text"
                                      disabled={
                                        chatModal.merchantPaid === false &&
                                        discussionDetails?.merchant_paid === 0
                                          ? true
                                          : false
                                      }
                                      onClick={seenPayment}
                                    >
                                      <CheckOutlined />
                                      seen payment
                                    </Button>

                                    <Button
                                      type="text"
                                      onClick={raiseIssues}
                                      // disabled={
                                      //   !raiseTimerIssue &&
                                      //   discussionDetails?.status !== "dispute"
                                      //     ? true
                                      //     : raiseTimerIssue
                                      //     ? // ||
                                      //       //   discussionDetails?.status ===
                                      //       //     "dispute"
                                      //       false
                                      //     : true
                                      // }

                                      disabled={
                                        !raiseTimerIssue &&
                                        discussionDetails?.status !== "dispute"
                                          ? true
                                          : discussionDetails?.status ===
                                            "dispute"
                                          ? true
                                          : false
                                      }
                                    >
                                      <QuestionOutlined />
                                      raise issue
                                    </Button>
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                        )}

                        {/* Hide the timer if the discussion has been cancelled */}
                        {discussionDetails.status === "canceled" ||
                        discussionDetails?.status === "completed" ? (
                          ""
                        ) : (
                          <div
                            style={{
                              color: "#999",
                              display: "flex",
                              gap: "5px",
                              alignItems: "center",
                              fontSize: 14,
                            }}
                          >
                            <ClockCircleOutlined />
                            &nbsp;
                            <Popconfirm
                              disabled={true}
                              title={
                                (stage &&
                                  discussionTimer?.interval === 1800 &&
                                  "this trade will automatically close after this countdown. click on instructions for more details") ||
                                (stage &&
                                  discussionTimer?.interval === 7200 &&
                                  "you will be able to enter a dispute after this countdown. click on instructions for more details") ||
                                (!stage &&
                                  discussionTimer?.interval === 1800 &&
                                  "you will be able to enter a dispute after this countdown click on instructions for more details") ||
                                (!stage &&
                                  discussionTimer?.interval === 7200 &&
                                  "you will be able to enter a dispute after this countdown. click on instructions for more details")
                              }
                              visible={chatModal.popOver}
                              placement="bottomRight"
                              showCancel={false}
                              onConfirm={() =>
                                handleOpenModal("popOver", false)
                              }
                              style={{ zIndex: "100" }}
                              overlayInnerStyle={{
                                maxWidth: "300px",
                                top: "800px",
                              }}
                            >
                              <>
                                {isTimeChange ? (
                                  <>{memoizedCountdown}</>
                                ) : !isTimeCompleted ? (
                                  <>
                                    {/* <Countdown
                                      date={countDownTime && countDownTime}
                                      // date={Date.now() + 20000}
                                      renderer={
                                        discussionTimer?.interval === 1800
                                          ? firstRenderer
                                          : secondRenderer
                                      }

                                      // renderer={firstRenderer}
                                    /> */}
                                    {memoizedCountdown}
                                  </>
                                ) : (
                                  <span
                                    onClick={() =>
                                      handleOpenModal("popOver", true)
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    00.00.00
                                  </span>
                                )}
                              </>
                            </Popconfirm>
                          </div>
                        )}
                      </div>
                    )}

                    {/*Badge messages for merchant and dealer on stage 1 and dispute badge message  */}
                    {issueHasBeenRaised ||
                    discussionDetails?.status === "dispute" ? (
                      <>
                        {discussionDetails?.stage === "1" && (
                          <div
                            className="instructions-wrap-two"
                            style={{ marginBottom: "15px" }}
                          >
                            <ExclamationCircleOutlined
                              style={{ color: "#14a014" }}
                            />

                            <div className="completed-action">
                              <p className="list-text">
                                {!isAdminEnteredChat
                                  ? `waiting for moderator to enter the trade`
                                  : `moderator is online now. you can both air your grievances`}
                              </p>
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {/* Hide section when the conversation gets to stage 2 */}

                        {discussionDetails?.stage === "2" ||
                        discussionDetails?.status === "completed" ? (
                          ""
                        ) : (
                          <div>
                            <div
                              className="instructions-wrap-two"
                              style={{ marginBottom: "15px" }}
                            >
                              <ExclamationCircleOutlined
                                style={{ color: "#14a014" }}
                              />
                              {discussionDetails.status === "canceled" ? (
                                <div className="completed-action">
                                  <p className="list-text">trade ended</p>
                                  {/* restart button only shows on the dealer side */}
                                  {!checkMerchant && (
                                    <>
                                      <div className="restart-box">
                                        <VscDebugRestart color="#14a014" />
                                        <button
                                          onClick={() =>
                                            history.push(
                                              `/deal/${discussionDetails?.deal_id}`
                                            )
                                          }
                                        >
                                          {" "}
                                          restart
                                        </button>
                                      </div>
                                    </>
                                  )}
                                </div>
                              ) : !checkMerchant ? (
                                <p className="list-text">
                                  {checkRaiseIssue
                                    ? `${discussionData?.merchant_data[0]?.user_name} has refused to confirm your payment. you can enter into a dispute by clicking on “raise an issue”.
                          `
                                    : !checkPaidBtn &&
                                      discussionDetails?.dealer_paid === 0
                                    ? "you have not paid yet"
                                    : checkPaidBtn ||
                                      discussionDetails?.dealer_paid === 1
                                    ? `waiting for ${discussionData?.merchant_data[0]?.user_name} to confirm your payment`
                                    : ""}
                                </p>
                              ) : (
                                <p className="list-text">
                                  {!checkPaidBtn &&
                                  discussionDetails?.dealer_paid === 0
                                    ? `waiting for ${discussionData?.dealer_data[0]?.user_name} to pay`
                                    : `confirm that you have seen the payment from ${discussionData?.dealer_data[0]?.user_name}`}{" "}
                                </p>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    )}

                    {/*Badge messages for merchant and dealer on stage 2  */}

                    {issueHasBeenRaised ||
                    discussionDetails?.status === "dispute" ? (
                      <>
                        {discussionDetails?.stage !== "1" && (
                          <div
                            className="instructions-wrap-two"
                            style={{ marginBottom: "15px" }}
                          >
                            <ExclamationCircleOutlined
                              style={{ color: "#14a014" }}
                            />

                            <div className="completed-action">
                              <p className="list-text">
                                {!isAdminEnteredChat
                                  ? `waiting for moderator to enter the trade`
                                  : `moderator is online now. you can both air your grievances`}
                              </p>
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {/* Badge messages for stage one */}
                        {discussionDetails?.stage !== "2" ||
                        discussionDetails?.status === "completed" ||
                        discussionDetails?.status === "cancelled" ? (
                          ""
                        ) : (
                          <div>
                            <div
                              className="instructions-wrap-two"
                              style={{ marginBottom: "15px" }}
                            >
                              <ExclamationCircleOutlined
                                style={{ color: "#14a014" }}
                              />
                              {discussionDetails.status === "canceled" ? (
                                <div className="completed-action">
                                  <p className="list-text">trade ended</p>
                                  {/* restart button only shows on the dealer side */}
                                  {!checkMerchant && (
                                    <>
                                      <div className="restart-box">
                                        <VscDebugRestart color="#14a014" />
                                        <button
                                          onClick={() =>
                                            history.push(
                                              `/deal/${discussionDetails?.deal_id}`
                                            )
                                          }
                                        >
                                          {" "}
                                          restart
                                        </button>
                                      </div>
                                    </>
                                  )}
                                </div>
                              ) : checkMerchant ? (
                                <p className="list-text">
                                  {checkRaiseIssue
                                    ? `${discussionData?.dealer_data[0]?.user_name} has refused to confirm your payment. you can enter into a dispute by clicking on “raise an issue”.
                          `
                                    : !checkPaidBtn &&
                                      discussionDetails?.merchant_paid === 0
                                    ? "you have not paid yet"
                                    : checkPaidBtn ||
                                      discussionDetails?.merchant_paid === 1
                                    ? `waiting for ${discussionData?.dealer_data[0]?.user_name} to confirm your payment`
                                    : ""}
                                </p>
                              ) : (
                                <p className="list-text">
                                  {!checkPaidBtn &&
                                  discussionDetails?.merchant_paid === 0
                                    ? `waiting for ${discussionData?.merchant_data[0]?.user_name} to pay`
                                    : `confirm that you have seen the payment from ${discussionData?.merchant_data[0]?.user_name}`}{" "}
                                </p>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    )}

                    {discussionDetails?.status === "completed" && (
                      <div
                        className="instructions-wrap-two"
                        style={{ marginBottom: "15px" }}
                      >
                        <ExclamationCircleOutlined
                          style={{ color: "#14a014" }}
                        />

                        <div className="completed-action">
                          <p className="list-text">trade completed</p>

                          {!checkMerchant && (
                            <>
                              <div className="restart-box">
                                <VscDebugRestart color="#14a014" />
                                <button
                                  onClick={() =>
                                    history.push(
                                      `/deal/${discussionDetails?.deal_id}`
                                    )
                                  }
                                >
                                  {" "}
                                  restart
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    )}

                    {/* {issueHasBeenRaised ||
                      (discussionDetails?.status === "dispute" && (
                        <div
                          className="instructions-wrap-two"
                          style={{ marginBottom: "15px" }}
                        >
                          <ExclamationCircleOutlined
                            style={{ color: "#14a014" }}
                          />

                          <div className="completed-action">
                            <p className="list-text">
                              {true
                                ? `wait for the moderator to enter the chat`
                                : `a moderator has entered the chat`}
                            </p>
                          </div>
                        </div>
                      ))} */}

                    <div className="actions-wrapper-section">
                      <div
                        style={{ opacity: "1", width: "47%" }}
                        onClick={() => setTab("chats")}
                      >
                        <Button
                          icon={<MessageOutlined />}
                          // color="success"
                          style={{
                            fontSize: "14px",
                            backgroundColor: tab === "chats" && "#fb4570",
                            color: tab === "chats" && "white",
                            padding: "3px 5px",
                            width: "100%",
                            height: "40px",
                            textAlign: "center",
                          }}
                        >
                          trade chat
                        </Button>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          fontSize: 14,
                          opacity: "1",
                          width: "46%",
                        }}
                        onClick={() =>
                          history.push({
                            pathname: "/instructions",
                            state: {
                              discussionDetails: discussionDetails,
                              checkMerchant: checkMerchant,
                              discussionData: discussionData,
                              merchantDetails: merchantDetails,
                            },
                          })
                        }
                      >
                        <Button
                          icon={<ExclamationCircleOutlined />}
                          style={{
                            fontSize: "14px",
                            backgroundColor:
                              tab === "instructions" && "#fb4570",
                            color: tab === "instructions" && "white",
                            padding: "3px 5px",
                            width: "100%",
                            height: "40px",
                            textAlign: "center",
                          }}
                        >
                          instructions
                        </Button>
                      </div>
                    </div>
                  </div>
                </Window>
              </Channel>
            </Chat>
          )}
        </div>
      </div>
    </>
  );
};

export default Discussion;
