import React, { useState, useEffect, useRef } from "react";
import { Select, Button, Modal, Input, message } from "antd";
import { Divider } from "antd";
import { SwapOutlined, DownOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import debounce from "lodash/debounce";
import Loader from "./../../components/Loader/Loader";
import { instance } from "./../../utils/API";
import DealItem from "./../../components/DealItem/DealItem";
import Footer from "./../../components/Footer/Footer.jsx";
import "./DealsList.scss";
import { ReactComponent as EmptyImage } from "./../../assets/empty.svg";
import { setDealsList, setHasError } from "./../../redux/data/data.actions";
import Banner from "../../components/Banner/banner";
import SEO from "../../components/SEO/seo";
import { Helmet } from "react-helmet";
import {
  // giftCardsList,
  // paymentInstruments,
  instrumentsList,
} from "../../utils/datasource.js";

const { Option } = Select;

export default function DealsList() {
  const [loadingMore, setLoadingMore] = useState(false);
  const [filterValue, setFilterValue] = useState("high to low");
  const [filterText, setFilterText] = useState("filter by: high to low");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [locationInput, setLocationInput] = useState("all");
  const [source, setSource] = useState("source");
  const [destination, setDestination] = useState("destination");
  const [sortText, setSortText] = useState("");
  const dispatch = useDispatch();
  const dealsList = useSelector((state) => state.data.dealsList);
  const [data, setData] = useState([]);
  const bannerRef = useRef();
  const containerRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);

    // if (!dealsList) {
    fetchDeals();
    // }
    //eslint-disable-next-line
  }, []);

  const fetchDeals = (
    page = 1,
    newest = 0,
    low2high = 0,
    high2low = 1,
    location = "all",
    source = "all",
    destination = "all",
    loadMore = null
  ) => {
    return instance
      .get(
        `/deals?page=${page}&newest=${newest}&low2high=${low2high}&high2low=${high2low}&location=${location}&source=${
          source.toLowerCase() === "bank account"
            ? "bank account"
            : source.toLowerCase() === "source"
            ? "all"
            : source.toLowerCase()
        }&destination=${
          destination.toLowerCase() === "bank account"
            ? "bank account"
            : destination.toLowerCase() === "destination"
            ? "all"
            : destination.toLowerCase()
        }`
      )
      .then(function (response) {
        if (loadMore) {
          handleLoadMore(response.data);
        } else {
          dispatch(setDealsList(response?.data));
        }
        setLoadingMore(false);
        setData(response.data);
      })
      .catch(function (error) {
        if (error?.response?.data?.message) {
          message.error(error?.response?.data?.message);
        } else {
          dispatch(setHasError(true));
        }
        setLoadingMore(false);
      });
  };

  const handleLoadMore = (fetchedData) => {
    const fetchedDataCopy = fetchedData;

    const oldDataArray = dealsList?.data;
    const fetchedDataArray = fetchedData?.data;

    const updatedArray = oldDataArray.concat(fetchedDataArray);

    fetchedDataCopy.data = updatedArray;

    dispatch(setDealsList(fetchedDataCopy));
    setLoadingMore(false);
  };

  const onFilterChange = (value) => {
    setFilterValue(null);
    if (value === "newest") {
      setLocationInput("all");
      setFilterText("filter by: newest");
      setSortText(value);
      dispatch(setDealsList(null));
      fetchDeals(
        1,
        1,
        0,
        0,
        locationInput,
        source ? source : "all",
        destination ? destination : "all"
      );
    } else if (value === "highToLow") {
      setLocationInput("all");
      setFilterText("filter by score: high to low");
      setSortText(value);
      dispatch(setDealsList(null));
      fetchDeals(
        1,
        0,
        0,
        1,
        locationInput,
        source ? source : "all",
        destination ? destination : "all"
      );
    } else if (value === "lowToHigh") {
      setLocationInput("all");
      setFilterText("filter by score: low to high");
      setSortText(value);
      dispatch(setDealsList(null));
      fetchDeals(
        1,
        0,
        1,
        0,
        locationInput,
        source ? source : "all",
        destination ? destination : "all"
      );
    } else if (value === "location") {
      showModal();
      setSortText("location");
    }
  };

  const onLocationInputChange = (value) => {
    setLocationInput(value);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    dispatch(setDealsList(null));
    setFilterText(`filter by location: ${locationInput}`);
    fetchDeals(
      1,
      0,
      0,
      0,
      locationInput,
      source ? source : "all",
      destination ? destination : "all"
    );
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onInstrumentChange = (type, value) => {
    dispatch(setDealsList(null));
    if (value === "source") {
      fetchDeals();
      setSource("all");
      setDestination("all");
    } else if (type === "source") {
      setSource(value);
      fetchDeals(
        1,
        1,
        0,
        0,
        locationInput,
        value,
        destination ? destination : "all"
      );
    } else {
      fetchDeals(1, 1, 0, 0, locationInput, source ? source : "all", value);
      setDestination(value);
    }
  };

  // scroll load

  // const [currentPage, setCurrentPage] = useState(dealsList?.meta?.page || 1);

  // useEffect(() => {
  //   const container = containerRef.current;

  //   const handleScroll = debounce(() => {
  //     const scrollHeight = container.scrollHeight;
  //     const scrollTop = container.scrollTop;
  //     const clientHeight = container.clientHeight;

  //     if (scrollTop + clientHeight >= scrollHeight - 100 && !loadingMore) {
  //       // Trigger load more functionality here
  //       if (dealsList?.meta && dealsList?.meta?.hasNext === true) {
  //         setLoadingMore(true);

  //         const nextPage = currentPage + 1;

  //         fetchDeals(
  //           nextPage,
  //           1,
  //           0,
  //           0,
  //           locationInput,
  //           source || "all",
  //           destination || "all",
  //           true
  //         ).then(() => {
  //           setCurrentPage(nextPage); // Update the page state
  //           setLoadingMore(false);
  //         });
  //       }
  //     }
  //   }, 300);

  //   if (container) {
  //     container.addEventListener("scroll", handleScroll);
  //   }

  //   // Cleanup the event listener on component unmount
  //   return () => {
  //     if (container) {
  //       container.removeEventListener("scroll", handleScroll);
  //     }
  //   };
  // }, [loadingMore, currentPage, dealsList?.meta]);

  return (
    <div className="deals-parent" ref={containerRef}>
      {/* <SEO title="" description="" type="" name="lily.africa" /> */}
      <Helmet>
        <meta
          name="description"
          content="Buy, Sell & Swap funds from the best merchants & vendors in Africa via over 100 different methods. buy, sell & swap zelle, paypal, cashapp, neteller, crypto, moneygram, gift cards, bank accounts, etc with secure escrow and excellent support."
        />

        <meta
          name="keywords"
          content="buy bitcoin, sell bitcoin, buy paypal, sell paypal, swap bank account to paypal, swap paypal to cashapp, swap venmo to paypal, swap paypal to venmo, swap cashapp to paypal, swap moneygram to paypal, swap paypal to moneygram, swap bitcoin to paypal, swap paypal to bitcoin, swap moneygram to cashapp, swap cashapp to moneygram, swap cashapp to bitcoin, swap bitcoin to cashapp, swap skrill to cashapp, swap cashapp to skrill, swap giftcard to paypal, swap paypal to giftcard "
        />

        <meta itemprop="name" content="Lily Africa" />
        <meta itemprop="url" content="https://lily.africa/" />

        <meta
          name="description"
          content="Buy, Sell & Swap Bitcoin, USDT, Gift cards, PayPal, Zelle, Payonner, Bank accounts, etc from the best merchants & vendors in Africa via over 100 different methods. Buy, Sell & Swap Zelle, Paypal, Cashapp, Neteller, Bitcoin, USDT, Moneygram, Gift cards, Bank accounts, etc with secure escrow and excellent support."
        />
        <meta name="author" content="Lily Africa" />

        <meta name="robots" content="index,follow" />

        <meta
          property="og:title"
          content="Lily.africa: Buy, Sell & Swap funds globally via over 100 methods."
        />
        <meta
          property="og:description"
          content="Buy, Sell & Swap Bitcoin, USDT, Gift cards, PayPal, Zelle, Payonner, Bank accounts, etc from the best merchants & vendors in Africa via over 100 different methods. Buy, Sell & Swap Zelle, Paypal, Cashapp, Neteller, Bitcoin, USDT, Moneygram, Gift cards, Bank accounts, etc with secure escrow and excellent support."
        />
      </Helmet>

      <Modal
        title="input location"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="ok"
        cancelText="cancel"
        width={350}
      >
        <Input
          placeholder="enter location"
          onChange={(event) => {
            onLocationInputChange(event.target.value);
          }}
        />
      </Modal>
      <div className="deals-container">
        <Banner bannerRef={bannerRef} />
        <div className="deals-wrapper">
          <div className="switch-bar">
            <div className="top-bar">
              <div className="left" ref={bannerRef}>
                deals
              </div>
              <div className="right">
                <div className="filter-by">
                  <div className="filter-text">{filterText}</div>
                  <Select
                    // value={filterValue}
                    suffixIcon={<DownOutlined />}
                    placeholder="filter by..."
                    optionFilterProp="children"
                    defaultValue={filterValue}
                    onChange={onFilterChange}
                  >
                    <Option value="newest">newest</Option>
                    <Option value="lowToHigh">score: low to high</Option>
                    <Option value="highToLow">score: high to low</Option>
                    <Option value="location">location</Option>
                  </Select>
                </div>
              </div>
            </div>

            <Divider></Divider>

            <div className="instrument-select">
              <div className="select-box">
                <p className="pair">i have</p>
                <Select
                  suffixIcon={
                    <DownOutlined
                      style={{
                        strokeWidth: "50",
                        stroke: "#ed1450",
                      }}
                    />
                  }
                  placeholder="select pair"
                  // defaultValue="all"
                  value={source}
                  onChange={(value) => {
                    onInstrumentChange("source", value);
                  }}
                  style={{
                    width: "100%",
                  }}
                >
                  {instrumentsList.map((item) => {
                    return <Option value={item}>{item}</Option>;
                  })}
                </Select>
              </div>

              <div className="arrows">
                <SwapOutlined
                  style={{
                    fontSize: "inherit",
                    strokeWidth: "15",
                    stroke: "#ed1450",
                  }}
                />
              </div>

              <div className="select-box">
                <p className="pair">i need</p>
                <Select
                  suffixIcon={
                    <DownOutlined
                      style={{
                        stroke: "#ed1450",
                        strokeWidth: "50",
                      }}
                    />
                  }
                  placeholder="select pair"
                  // defaultValue="all"
                  value={destination}
                  onChange={(value) => {
                    onInstrumentChange("destination", value);
                  }}
                  style={{
                    width: "100%",
                  }}
                >
                  {instrumentsList.map((item) => {
                    return <Option value={item}>{item}</Option>;
                  })}
                </Select>
              </div>
            </div>
          </div>

          {!dealsList ? <Loader /> : null}

          {dealsList && dealsList?.data.length === 0 ? (
            <div className="no-result">
              <div className="svg-container">
                <EmptyImage />
              </div>

              <div className="no-result-text">no deal found</div>
              <div className="no-result-text-bottom">
                try another combination
              </div>
            </div>
          ) : null}

          {dealsList && dealsList?.data.length !== 0 ? (
            <div className="deals-list">
              {/* filter types */}
              {sortText === "newest" ? (
                <>
                  {dealsList &&
                    dealsList?.data.map((item) => (
                      <DealItem
                        item={item}
                        key={`${item.id}${Math.random()}`}
                      />
                    ))}
                </>
              ) : sortText === "lowToHigh" ? (
                <>
                  {dealsList &&
                    dealsList?.data
                      ?.slice()
                      .sort(
                        (item, comp) =>
                          item?.dealer_reputation_data?.trust_score -
                          comp?.dealer_reputation_data?.trust_score
                      )
                      .map((item) => (
                        <DealItem
                          item={item}
                          key={`${item.id}${Math.random()}`}
                        />
                      ))}
                </>
              ) : sortText === "highToLow" ? (
                <>
                  {dealsList &&
                    dealsList?.data
                      ?.slice()
                      .sort(
                        (item, comp) =>
                          comp?.dealer_reputation_data?.trust_score -
                          item?.dealer_reputation_data?.trust_score
                      )
                      .map((item) => (
                        <DealItem
                          item={item}
                          key={`${item.id}${Math.random()}`}
                        />
                      ))}
                </>
              ) : (
                <>
                  {dealsList &&
                    dealsList?.data.map((item) => (
                      <DealItem
                        item={item}
                        key={`${item.id}${Math.random()}`}
                      />
                    ))}
                </>
              )}
            </div>
          ) : null}

          {dealsList &&
            dealsList?.data.length !== 0 &&
            dealsList?.meta?.hasNext && (
              <div className="load-more">
                <div>
                  {dealsList && dealsList?.meta?.hasNext && (
                    <Button
                      loading={loadingMore}
                      type="default"
                      className="login-form-button short"
                      // disabled
                      onClick={() => {
                        setLoadingMore(true);
                        fetchDeals(
                          dealsList?.meta?.page + 1,
                          1,
                          0,
                          0,
                          locationInput,
                          source ? source : "all",
                          destination ? destination : "all",
                          true
                        );
                      }}
                      style={{ fontWeight: "500" }}
                    >
                      load more
                    </Button>
                  )}
                </div>

                <div className="results-count">
                  {`showing 1 - ${dealsList?.meta?.end} of 
                ${dealsList?.meta?.total} deals.`}
                </div>
              </div>
            )}

          {dealsList &&
            dealsList?.data.length !== 0 &&
            !dealsList?.meta?.hasNext && (
              <div className="list-end">looks like you've reached the end</div>
            )}
        </div>
      </div>
      <Footer />
    </div>
  );
}
